@font-face {
  font-family: 'BentonSans';
  src: url('/fonts/BentonSans/benton-sans-light/.eot');
  src: url('/fonts/BentonSans/benton-sans-light/benton-sans-light.eot') format('embedded-opentype'),
  url('/fonts/BentonSans/benton-sans-light/benton-sans-light.woff') format('woff'),
  url('/fonts/BentonSans/benton-sans-light/benton-sans-light.ttf') format('truetype'),
  url('/fonts/BentonSans/benton-sans-light/benton-sans-light#BentonSansRegular') format('svg');
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'BentonSans';
  src: url('/fonts/BentonSans/benton-sans-light-italic/.eot');
  src: url('/fonts/BentonSans/benton-sans-light-italic/benton-sans-light-italic.eot') format('embedded-opentype'),
  url('/fonts/BentonSans/benton-sans-light-italic/benton-sans-light-italic.woff') format('woff'),
  url('/fonts/BentonSans/benton-sans-light-italic/benton-sans-light-italic.ttf') format('truetype'),
  url('/fonts/BentonSans/benton-sans-light-italic/benton-sans-light-italic#BentonSans') format('svg');
  font-weight: 300;
  font-style: italic;
}




@font-face {
  font-family: 'BentonSans';
  src: url('/fonts/BentonSans/benton-sans-regular/.eot');
  src: url('/fonts/BentonSans/benton-sans-regular/benton-sans-regular.eot') format('embedded-opentype'),
  url('/fonts/BentonSans/benton-sans-regular/benton-sans-regular.woff') format('woff'),
  url('/fonts/BentonSans/benton-sans-regular/benton-sans-regular.ttf') format('truetype'),
  url('/fonts/BentonSans/benton-sans-regular/benton-sans-regular#BentonSansRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'BentonSans';
  src: url('/fonts/BentonSans/benton-sans-regular-italic/.eot');
  src: url('/fonts/BentonSans/benton-sans-regular-italic/benton-sans-regular-italic.eot') format('embedded-opentype'),
  url('/fonts/BentonSans/benton-sans-regular-italic/benton-sans-regular-italic.woff') format('woff'),
  url('/fonts/BentonSans/benton-sans-regular-italic/benton-sans-regular-italic.ttf') format('truetype'),
  url('/fonts/BentonSans/benton-sans-regular-italic/benton-sans-regular-italic#BentonSans') format('svg');
  font-weight: normal;
  font-style: italic;
}


@font-face {
  font-family: 'BentonSans';
  src: url('/fonts/BentonSans/benton-sans-medium/.eot');
  src: url('/fonts/BentonSans/benton-sans-medium/benton-sans-medium.eot') format('embedded-opentype'),
  url('/fonts/BentonSans/benton-sans-medium/benton-sans-medium.woff') format('woff'),
  url('/fonts/BentonSans/benton-sans-medium/benton-sans-medium.ttf') format('truetype'),
  url('/fonts/BentonSans/benton-sans-medium/benton-sans-medium#BentonSansRegular') format('svg');
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'BentonSans';
  src: url('/fonts/BentonSans/benton-sans-medium-italic/.eot');
  src: url('/fonts/BentonSans/benton-sans-medium-italic/benton-sans-medium-italic.eot') format('embedded-opentype'),
  url('/fonts/BentonSans/benton-sans-medium-italic/benton-sans-medium-italic.woff') format('woff'),
  url('/fonts/BentonSans/benton-sans-medium-italic/benton-sans-medium-italic.ttf') format('truetype'),
  url('/fonts/BentonSans/benton-sans-medium-italic/benton-sans-medium-italic#BentonSans') format('svg');
  font-weight: 500;
  font-style: italic;
}


@font-face {
  font-family: 'BentonSans';
  src: url('/fonts/BentonSans/benton-sans-medium/.eot');
  src: url('/fonts/BentonSans/benton-sans-medium/benton-sans-medium.eot') format('embedded-opentype'),
  url('/fonts/BentonSans/benton-sans-medium/benton-sans-medium.woff') format('woff'),
  url('/fonts/BentonSans/benton-sans-medium/benton-sans-medium.ttf') format('truetype'),
  url('/fonts/BentonSans/benton-sans-medium/benton-sans-medium#BentonSansRegular') format('svg');
  font-weight: 600;
  font-style: normal;
}


@font-face {
  font-family: 'BentonSans';
  src: url('/fonts/BentonSans/benton-sans-medium-italic/.eot');
  src: url('/fonts/BentonSans/benton-sans-medium-italic/benton-sans-medium-italic.eot') format('embedded-opentype'),
  url('/fonts/BentonSans/benton-sans-medium-italic/benton-sans-medium-italic.woff') format('woff'),
  url('/fonts/BentonSans/benton-sans-medium-italic/benton-sans-medium-italic.ttf') format('truetype'),
  url('/fonts/BentonSans/benton-sans-medium-italic/benton-sans-medium-italic#BentonSans') format('svg');
  font-weight: 600;
  font-style: italic;
}



@font-face {
  font-family: 'BentonSans';
  src: url('/fonts/BentonSans/benton-sans-bold/.eot');
  src: url('/fonts/BentonSans/benton-sans-bold/benton-sans-bold.eot') format('embedded-opentype'),
  url('/fonts/BentonSans/benton-sans-bold/benton-sans-bold.woff') format('woff'),
  url('/fonts/BentonSans/benton-sans-bold/benton-sans-bold.ttf') format('truetype'),
  url('/fonts/BentonSans/benton-sans-bold/benton-sans-bold#BentonSans') format('svg');
  font-weight: 700;
  font-style: normal;
}



@font-face {
  font-family: 'BentonSans';
  src: url('/fonts/BentonSans/benton-sans-black-italic/.eot');
  src: url('/fonts/BentonSans/benton-sans-black-italic/benton-sans-black-italic.eot') format('embedded-opentype'),
  url('/fonts/BentonSans/benton-sans-black-italic/benton-sans-black-italic.woff') format('woff'),
  url('/fonts/BentonSans/benton-sans-black-italic/benton-sans-black-italic.ttf') format('truetype'),
  url('/fonts/BentonSans/benton-sans-black-italic/benton-sans-black-italic#BentonSans') format('svg');
  font-weight: 700;
  font-style: italic;
}


html,body{
  padding: 0;
  margin: 0;

  //overflow: hidden;
}

body{
  //font-family: proxima_nova, Helvetica, Arial, sans-serif;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
  //color: #222;
  //font-size: 16px;
  //background: #FFF;
  //font-weight: 400;
  //background: #f4f6fc;//f4f6fc
  font-family: 'BentonSans', sans-serif!important;
  -webkit-font-smoothing: auto;
}

.page-wrap{
  //overflow: hidden;
  padding-bottom: 50px;

}

.wrap{
  padding: 50px 0;

  @media (max-width: 600px) {
      padding: 20px 0;
  }

}


.portal-page-content,
.project-scope-details-wrap,
.make-request-form-wrap,
.service-plan-content-text,
.service-item{

  //font-size: 15px;
  //color: #000;
  //font-weight: 300;

  strong{
    //font-size: 16px;
    //font-weight: 400;
    //letter-spacing: -.25px;
  }
}


main{
}

.App{
  overflow: hidden;
}

ul{
  margin: 0;
  padding: 0;
}

img{
  max-width: 100%;
  height: auto;
}

h1,h2,h3,h4,h5,h6{
  color: $primary;
}

.text-primary, a{
  color: $primary;
}

a:hover{
  color: #0092D6;
}

.text-primary{
  color: $primary!important;
}

.container-fluid{
  max-width: 1300px;
}


body .sweet-alert{

  padding: 30px !important;
  border-radius: 5px !important;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.4);

  h2{
    color: #333!important;
    font-size: 1.2em!important;
    margin-top: 0!important;
    margin-bottom:20px;
  }

  p{
    color: #333!important;
    font-size: 14px;
  }

  button {
    font-size: 14px;
    border-radius: 3px;
    font-weight: 600;
    padding: 6px 20px;
  }

  button.cancel{

    background-color: #6b6b6b;
    transition: all .4s ease;

    &:hover{
      background-color: #545454;
    }

  }
  button.confirm{
    background-color: $primary!important;
    box-shadow: none!important;
    transition: all .4s ease;

    &:hover{
      background-color: #0089C9!important;
    }
  }

  strong{
    font-weight: bold;
  }

  &.container-delete-alert{


    h2 {
      color: #da493f !important;
    }
    button.confirm{
      background-color: #da493f!important;

      &:hover{
        background-color: #c24138!important;
      }
    }

  }
}



.modalxxx{

  .modal-header{
    padding: 0;
    position: relative;
    border:0;

    h5 {
      background: #6bebfe;
      background: -moz-linear-gradient(left, #6bebfe 0%, #768cff 100%);
      background: -webkit-linear-gradient(left, #6bebfe 0%, #768cff 100%);
      background: linear-gradient(to right, #6bebfe 0%, #768cff 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6bebfe', endColorstr='#768cff',GradientType=1 );
      padding: 20px 30px !important;
      width:100%;
      color: #FFF !important;
      text-transform: uppercase;
      font-size: 20px !important;
    }



  }

  .close{
    position: absolute;
    width: 30px;
    height: 30px;
    background: #718eff;
    text-align: center;
    padding: 0;
    border-radius: 50%;
    opacity: 1;
    right: 6px;
    top: -18px;
    color: #FFF;
    text-shadow: none;
    transition: all .4s ease;
    font-family: Times;
    font-weight: normal;

    &:hover{
      opacity: 1!important;
      box-shadow: 0 0 0 3px #FFF;
    }

    span{
      display: inline-block;
    }
  }

  &.no-header{
    .close{
      top:-15px;
      right:-10px;
      box-shadow: 0 0 0 3px #FFF;
      z-index: 10;

      &:hover{
        background: #6bebfe;
      }
    }
  }

  .modal-content{
    border: 0;
    box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
  }

  .modal-body{
    padding: 35px 30px !important;

    .alert{
      margin-top: 10px;
    }

    textarea{
      resize: none;
    }

    .form-label{
      font-weight: bold;
      text-transform: uppercase;
      color: #333;
      margin-bottom:5px;
      font-size: 13px;
    }

    button{
      border: 0!important;
    }
  }

  .modal-footer{
    padding: 15px 30px;
  }

  button{
    margin-top:0!important;
  }
}



.fa-spinner.fa-spin{
  opacity: .3;
}

@-webkit-keyframes fadeInFadeOut {
  0% { opacity: 1 }
  50% { opacity:  .4 }
  100% { opacity: 1 }
}
@-moz-keyframes fadeInFadeOut {
  0% { opacity: 1 }
  50% { opacity:  .4 }
  100% { opacity: 1 }
}
@-o-keyframes fadeInFadeOut {
  0% { opacity: 1 }
  50% { opacity:  .4 }
  100% { opacity: 1 }
}

.loading-item{

  -webkit-animation: fadeInFadeOut 1s infinite ease;
  -moz-animation: fadeInFadeOut 1s infinite ease;
  -o-animation: fadeInFadeOut 1s infinite ease;
}




.load-bar {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 3px;
  background-color: #FFF;
}
.bar {
  content: "";
  display: inline;
  position: absolute;
  width: 0;
  height: 100%;
  left: 50%;
  text-align: center;
}
.bar:nth-child(1) {
  background-color: #46d4d7;
  animation: loading 3s linear infinite;
}
.bar:nth-child(2) {
  background-color: $primary;
  animation: loading 3s linear 1s infinite;
}
.bar:nth-child(3) {
  background-color: #FFF;
  animation: loading 3s linear 2s infinite;
}
@keyframes loading {
  from {left: 50%; width: 0;z-index:100;}
  33.3333% {left: 0; width: 100%;z-index: 10;}
  to {left: 0; width: 100%;}
}



.nav-aside {
  .nav-item .nav-link{
    font-size: 13px;
    font-weight: 400!important;
    height: 25px!important;
  }

  .nav-item .nav-link.active {
    opacity: 1;
    color: $primary;
    font-weight: 500;

    svg{
      color: $primary;
    }
  }

  .nav-item ul{
    padding-bottom: 0!important;

    a.active{
      color: $primary!important;
    }
  }
}


.aside-fixed{
  width: 240px!important;

  .aside-body{
    overflow-y: auto;
    overflow-x: hidden!important;
    height: calc(100% - 59px);
    padding-top:15px;

    @media (min-width: 1025px) {
      &::-webkit-scrollbar {
        width: 5px;
        position: absolute;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.3);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.5);
      }
    }
  }

  &.aside-fixed.minimize{
    .aside-body{
      overflow: hidden!important;

      &:hover{
        overflow-y: auto!important;
        overflow-x: hidden!important;
      }
    }
  }
}

.project-book-page{
  .aside-fixed {
    .aside-body {
      height: calc(100% - 108px);
    }
  }
}


.aside-header{

  .aside-logo{
    margin-top: -8px;
    img{
      max-width: 120px;
    }
  }
}


@media (max-width: 767px) {


  .aside.minimize + .content {
    margin-left: 0;
  }

  .aside-header {
    height: 55px!important;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
  }

}


@media (min-width: 1200px){
  .aside-fixed + .content {
    margin-left: 240px!important;
  }

  .aside.minimize{
    width: 60px!important;
  }

  .aside.minimize + .content {
    margin-left: 60px!important;
  }
}


.navbar-header{

  .dropdown-profile{


    &:after{
      bottom:-10px;
      left: -40px;
      right: -40px;
      height: 10px;
      position: absolute;
      content: '';
    }


    .dropdown-menu{
      display: block!important;
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      transform: translateY(20px);
      transition: all .4s ease!important;

    }

    &:hover{
      .dropdown-menu{
        pointer-events: inherit;
        opacity: 1;
        display: block;
        visibility: visible;
        transform: translateY(0);
      }
    }
  }

  .navbar-menu-sub{

  }
  .navbar-menu{

    .nav-item{

      ul{
        transition: all .4s ease!important;
        top: 61px;
        display: block;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transform: translateY(20px);
      }

      &:hover{

        ul{
          pointer-events: inherit;
          opacity: 1;
          display: block;
          visibility: visible;
          transform: translateY(0);
        }
      }
    }
    .nav-link{
      height: 61px;
    }

    .nav-link.cur,
    .nav-link.active{
      color: $primary;
    }
  }

}

body.with-aside{

  .navbar-header{
    .navbar-brand{
      opacity: 0;
      pointer-events: none;
    }
  }
}

.nav-aside .nav-label{
  font-family: 'BentonSans', sans-serif!important;
}

@media (max-width: 991px) {



  .navbar-header {

    border-bottom: 1px solid rgba(72, 94, 144, 0.16);

    .burger-menu{
      display: none!important;
    }

    .navbar-brand{
      opacity: 1!important;
      visibility: visible!important;;
    }

  }

  .aside-fixed{
    left:auto!important;
    right: 0!important;

    -webkit-transform: translateX(100%)!important;
    transform: translateX(100%)!important;;
    transition: transform .5s ease;

    .aside-body{
      border-top: 0;
    }
  }

  .navbar-header{
    .with-aside &{
      padding-right: 50px!important;
    }

  }



  .aside-header{
    transform: translateX(-55px)!important;
    border-left: 1px solid rgba(72, 94, 144, 0.16);
    border-right: 0;

    .aside-logo{
      display: none;
    }
  }

  .show-aside {

    .aside {
      -webkit-transform: translateX(0)!important;
      transform: translateX(0)!important;
    }

    .aside-header{
      transform: translateX(0)!important;
      //border-bottom: 0;
      border-left: 0;

      .aside-logo{
        display: block;
      }
    }
  }

}


@media (max-width: 767px){
  .aside.minimize + .content {
    margin-left: 0!important;
  }
}

