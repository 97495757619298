@import "Variable";

header.navbar-header{

    .navbar-brand{
        img{
            max-width: 120px;
        }
    }

    .dropdown-profile{

        a{
            outline: none!important;
        }
    }

    .navbar-right .dropdown-menu{
        margin-top: 6.5px;

        &.dropdown-menu-right {
            margin-right: -1px;
        }
    }


}



.dropdown-profile .dropdown-link:hover .avatar img,
.dropdown-profile .dropdown-link:focus .avatar img{
    transform: scale(1.1);
}


@media (max-width: 767px) {

    header.navbar-header .navbar-right .dropdown-menu.dropdown-menu-right {
        margin-right: -12px;
    }

}