.profile-header{

  position: relative;
  font-size: 14px;
  margin-top: -20px;

  a{
    text-decoration: none;
  }

  h4{
    margin:0;
    text-transform: uppercase;
    color: #333;
    font-size: 1.4em;
  }

  .profile-photo{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    line-height:58px;
    position: absolute;
    left: 0;
    top: 0;
    background: #FFF;
    text-align: center;

    img{
      max-width: 30px;
    }

  }

  .profile-content{
    position: relative;
    z-index: 2;
    padding-bottom:20px;
    margin-bottom: 20px;
    padding-left: 80px;
  }

  &:after{
    z-index: 1;
    background: #f6fafd;
    content: '';
    position: absolute;
    bottom: 0;
    left:-50px;
    right:-50px;
    top:-30px;
  }


  @media (max-width: 767px) {

    text-align: center;

    h4{
      font-size: 1.3em;
    }

    .profile-photo{
      position: relative;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    .profile-content{
      padding-bottom:25px;
      margin-bottom: 30px;
      padding-left: 0;
    }



  }

  @media (max-width: 600px) {


    &:after{
      bottom: 0;
      left:-20px;
      right:-20px;
      top:0px;
    }

  }


}


.profile-form{

  label{
    display: block;
    position: relative;
    padding-top: 20px;
    border-bottom: 1px solid #ededed!important;

    &:after{
      left: 0;
      right: 0;
      height: 1px;
      background: #00A3EF;
      content: '';
      position: absolute;
      bottom: 0;
      transition: all .4s ease;
      transform: scale(0);
    }

    &.focus{

      &:after{
        transform: scale(1);
      }

      span.label-text{
        color: #00A3EF;
      }

      span.label-text{

      }


    }

    .form-control{
      border-radius: 0;
      border: 0!important;
      font-weight: 300;
      font-size: 15px;
      padding-left:0;
      padding-right:0;
      transition: all .4s ease;
    }

    span.label-text{
      display: block;
      position: absolute;
      padding-left: 0;
      top: 0;
      color: #9c9c9c;
      font-size: 12px;
      pointer-events: none;
      transition: all .4s ease;
    }
  }
}


.profile-meta{
  .profile-photo{
    min-width: 80px;
  }



  @media (max-width: 767px) {

    .profile-email{
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width:260px;
    }

    .profile-photo{
      width:50px!important;
      height: 50px!important;
      min-width: 50px;
      img{
        width:50px!important;
        height: 50px!important;
        border-width: 2px!important;
      }
    }
  }
}

body.client-account-page{

  .page-content{
    max-width: 800px;
    font-size: 14px;
  }


}

