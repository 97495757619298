

.tasks-wrap-card{
  .card-body{
    padding: 30px!important;
  }
}

.tasks-wrap{


  .tasks-scroller{
    position: relative;
  }

  .task-row{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

    .task-col-1,
    .task-col-2,
    .task-col-3,
    .task-col-4,
    .task-col-5,
    .task-col-6,
    .task-col-7,
    .task-col-8,
    .task-col-9,
    .task-col-10,
    .task-col-11,
    .task-col-12{
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;

    }

    .task-col-1{
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    .task-col-2{
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    .task-col-3{
      flex: 0 0 25%;
      max-width: 25%;
    }
    .task-col-4{
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .task-col-5{
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    .task-col-6{
      flex: 0 0 50%;
      max-width: 50%;
    }
    .task-col-7{
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    .task-col-8{
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    .task-col-9{
      flex: 0 0 75%;
      max-width: 75%;
    }
    .task-col-10{
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    .task-col-11{
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    .task-col-12{
      flex: 0 0 100%;
      max-width: 100%;
    }
  }



  .task-header{

    color: #8392a5;
    letter-spacing: .5px;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px solid rgba(72, 94, 144, 0.1);
    padding-bottom: 20px!important;

  }

  .task-item{
    padding: 10px 0 10px 0;
    background: #FFF;

    .task-item{

      border-top: 1px solid rgba(72, 94, 144, 0.1);
      //cursor: move;

      .task-title{
        margin-left: 30px;
      }

    }

    + .task-item{
      border-top: 1px solid rgba(72, 94, 144, 0.1);
    }

    &.has-child{
      //padding-bottom: 0;
    }

    .task-sub-tasks{
      padding-top: 5px;
      display: none;
    }

    .task-title{
      position: relative;
      padding-left: 30px;
      padding-right: 50px;
      margin-top: 5px;
      min-height: 30px;
    }

    .task-editable{
      padding-right: 3px;
    }

    [contenteditable="true"] {
      border-bottom: 1px dashed rgba(72, 94, 144, 1);
      outline: none!important;

    }

    .expand-btn{
      position: absolute;
      left: -20px;
      font-size: 10px;
      top: 5px;
      visibility: hidden;
    }

    &:hover{
      .expand-btn{
        visibility: visible;
      }
    }

    &.expanded{
      .expand-btn{
        transform: rotate(-90deg);
      }
    }

    .task-checkbox-wrap{
      position: absolute;
      width: 16px;
      height:  16px;
      left: 0;
      top: 3px;

      &:hover{
        .status-change-container{
          visibility: visible;
          opacity: 1;
          transform: translateY(0);
        }
      }

      .status-change-container{
        visibility: hidden;
        opacity: 0;
        transform: translateY(50px);
        position: absolute;
        left:16px;
        top: 50%;
        height:  123px;
        width: 160px;
        margin-top: -60px;
        background: #FFF;
        z-index: 2;

        border-color: rgba(28, 39, 60, 0.12);
        box-shadow: 0 0 8px rgba(28, 39, 60, 0.08);
        border-radius: 0.25rem;
        padding: 10px;
        transition: all 0.3s cubic-bezier(0.5, 0.005, 0.075, 0.985);
        border: 1px solid rgba(72, 94, 144, 0.16);


        &:after, &:before {
          right: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:after {
          border-color: rgba(255, 255, 255, 0);
          border-right-color: #FFF;
          border-width: 4px;
          margin-top: -4px;
        }
        &:before {
          border-color: rgba(72, 94, 144, 0);
          border-right-color: rgba(72, 94, 144, 0.16);
          border-width: 5px;
          margin-top: -5px;
        }


        div{
          padding: 5px;
          text-align: center;
          border: 1px solid #8392a5;
          border-radius: 3px;
          font-size: 12px;
          cursor: pointer;
          transition: background .4s ease;

          + div{
            margin-top: 5px;
          }

          &.pending{
            border-color: #8392a5;
            background: #8392a5;
            color: #FFF;

            &:hover{
              background: #FFF;
              color: #8392a5;
            }
          }

          &.in-progress{
            border-color: #fac166;
            color: #FFF;
            background: #fac166;

            &:hover{
              background: #FFF;
              color: #fac166;
            }
          }

          &.completed{
            border-color: #84cb88;
            color: #FFF;
            background: #84cb88;

            &:hover{
              background: #FFF;
              color: #84cb88;
            }
          }
        }
      }
    }


    .col-editable-popup{

      cursor: pointer;

      span{
        vertical-align: top;
        overflow: hidden;
      }

      &:hover{
        //background: #f5f6fa;
      }
    }

    .task-checkbox{
      border: 1px solid rgba(72, 94, 144, 0.16);
      border-radius: 2px;
      width: 16px;
      height:  16px;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;

      &:after{
        text-align: center;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        line-height: 15px;
        font-size: 8px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
      }

      &.in-progress{

        color: #FFF;
        background: #fac166;
        border-color: #fac166;

        &:after{
          background-position: center;
          background-size: 70%;
          background-repeat: no-repeat;
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyVpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ4IDc5LjE2NDAzNiwgMjAxOS8wOC8xMy0wMTowNjo1NyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjAgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MDI0NTcwRkRDNUQ2MTFFQUE0OUE4MERCNDIyOUI5MjAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MDI0NTcwRkVDNUQ2MTFFQUE0OUE4MERCNDIyOUI5MjAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowMjQ1NzBGQkM1RDYxMUVBQTQ5QTgwREI0MjI5QjkyMCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowMjQ1NzBGQ0M1RDYxMUVBQTQ5QTgwREI0MjI5QjkyMCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pq2fevEAAAGUSURBVHja5Nk9bsIwFMBxx1SciRN0RN0rEaDdWDkGN+jQ8jWzcwIuRdPnCksMmMSJ7ff1pCckFvT7RwErVE3TGIR5gz0Z3LnArq3ROxPYjeYA/xG0BzAV0neACz8u9FnvsF/OSimAKYjfwo6eXQlN+EZLgEf4X9iVhgAh/Cfst/QAUXhpAaLxkgL0wksJ0BsvIcAgPPcAg/FuXoThP2B/Ys/kavEcAyTFcwuQHM8pQBY8lwDZ8BwCZMVTD5AdT/kcEMIvb+8nG6sZTzFAUTy1AMXxlAKg4KkEQMNTCICKxw6AjscMQALvBuOvsRB+AbsrcMGnmAEw8ei3ADl8yQAk8aUCkMWXCEAanzsAeXzOACzwuR6IhPBz2D21hw9WMz51AHb4lAFY4lMFYItPEYA1fmgA9vghAUTg+54DQvga9mCYjdWMjw0gDh8TQCS+awCx+C4BROPbAojHPwugAh8KoAb/6CAUws9gj0bg2Bb8VTL+PkAIX0vG+wBq8T7Aq1a8D7C8w6rC+18Bj3avZ014N38CDADTdcFt4MVmvQAAAABJRU5ErkJggg==);

        }
      }

      &.completed{
        color: #FFF;
        background: #84cb88;
        border-color: #84cb88;

        &:after{

          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyVpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ4IDc5LjE2NDAzNiwgMjAxOS8wOC8xMy0wMTowNjo1NyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjAgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MDI0NTcwRjlDNUQ2MTFFQUE0OUE4MERCNDIyOUI5MjAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MDI0NTcwRkFDNUQ2MTFFQUE0OUE4MERCNDIyOUI5MjAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowMjQ1NzBGN0M1RDYxMUVBQTQ5QTgwREI0MjI5QjkyMCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowMjQ1NzBGOEM1RDYxMUVBQTQ5QTgwREI0MjI5QjkyMCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PlkcaMkAAAHMSURBVHja7Nu7SgNBFMbxzSKKPoNgwN4nUBGvjb6Kdd7A3srOytrCVkXE93AtYmWdgArjN+KCLHuZmd2ZzDlnBj5BLdz/D/GySUZKqUzyyTPhJwEkgASQABKAsLOC7WBLEgHOsSn2jL1i2yNBfwhNsIvKx+6kANTF61PkguP1ueL+HdAW/4id5oLjz7B5Ljh+xvXXoHE8RwCreG4A1vGcAJziuQA4x3MA6BVPHaB3PGWAQeKpAgwWTxFg0HhqAIPHUwLwEk8FwFs8BQCv8bEDeI+PGSBIfKwAweJjBAgaHxtA8PiYABYSXwVYlRb/e5RSm9gt9oXdY7v6sYJAm6jm84Ct+b4G/ea68oXn2ImE+BJgWnMBvhGiiC8BLhsuRCMcc44vAdaxIhBCVPElgN64A+GIY/x/AN8IUcZXAUwQDjnF1wF0IcwsEaKObwIYCiH6+DYAE4QD6vFdAK4IZOJNAEwQ9qnGmwKYIpCL17N5ltgYe8I2aj73iS0v7F/aHsf2aXJtCOTiXe4IFdge9sYh3vWWmAkCifg+9wTbEMjE970pWiK8/L3/jd1Qinf5Idh0trAP7D0jdiS9XoDsw+MJIAEkgASQAHydHwEGAFliuaPYZWWFAAAAAElFTkSuQmCC);
          background-position: center;
          background-size: 70%;
          background-repeat: no-repeat;
        }
      }
    }


    .task-status{

      margin-top: 5px;
      display: inline-block;

      &.pending{
        color: #8392a5;
      }

      &.in-progress{
        color: #fac166;
      }

      &.completed{
        color: #84cb88;
      }
    }

    .task-actions{
      position: absolute;
      right: 0;
      font-size: 10px;
      opacity: 0;
      visibility: hidden;
      padding: 4px;
      background: #FFF;

      .action-btn{
        cursor: pointer;
        margin-left: 10px;
      }
    }

    &:hover{
      >.task-row .task-title .task-actions{
        visibility: visible;
        opacity: 1;
      }
    }
  }



  .task-footer{
    padding-top: 20px;

    .new-task{
      cursor: pointer;

      &:hover{
        color: $primary;
      }
    }
  }




  @media (max-width: 992px) {

    overflow-y: hidden;
    overflow-x: auto;

    .tasks-scroller{
      min-width: 900px;
    }

    .col-status{
    }
  }


}



.task-item-popup{
  box-shadow: 0 0 20px 0 rgba(0,0,0, .16);
  background: #FFF;
  border-radius: 3px;
  position: absolute;
  border: 1px solid rgba(72, 94, 144, 0.16);
  //margin-left: -286px;
  //margin-top: -177px;
  width: 200px;


  h4{
    padding: 10px 20px 10px 20px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 10px;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
    margin: 0;
    color: #8392a5;
  }

  ul{

    margin: 0;
    padding: 0;
    max-height: 200px;
    overflow-y: auto;

    li{
      display: block;
      list-style: none;
      padding: 5px 20px;
      cursor: pointer;
      font-size: 12px;
      position: relative;

      + li{
        border-top: 1px solid rgba(72, 94, 144, 0.16);

      }

      .fa{
        font-size: 11px;
        color: #8392a5;
        position: absolute;
        right: 7px;
        top:50%;
        margin-top: -10px;
      }

      &:hover{
        background: #f5f6fa;
      }
    }
  }

  @media (max-width: 992px) {

  }
}
