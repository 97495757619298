@import "Variable";



.tip-box{

  box-shadow: 0 0 40px 0 rgba(51, 51, 51, 0.05);
  border-radius: 5px;
  padding: 30px;
  margin-bottom:20px;
  position: relative;


  h5{
    text-transform: uppercase;
    color: $primary;
    position: relative;
    font-size: 1.1em;
    margin-bottom: 1.5em;

    i{
      font-size: .8em;
      margin-right:5px;
      width:24px;
      height: 24px;
      line-height: 24px;
      border-radius: 50%;
      text-align: center;
      display: inline-block;
      color: #FFF;
      background: #7c81f4;
      background: -moz-linear-gradient(left, #7c81f4 0%, #03ccd9 100%);
      background: -webkit-linear-gradient(left, #7c81f4 0%, #03ccd9 100%);
      background: linear-gradient(to right, #7c81f4 0%, #03ccd9 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7c81f4', endColorstr='#03ccd9',GradientType=1 );
    }
  }

  p{
    font-size: 13px;
  }

  ul{
    li{
      padding-left:25px;
      position: relative;
      font-size: 13px;
      list-style: none;
      margin-bottom:5px;

      &:before{
        width:6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        left: 6px;
        content: '';
        background: #7c81f4;
        background: -moz-linear-gradient(left, #7c81f4 0%, #03ccd9 100%);
        background: -webkit-linear-gradient(left, #7c81f4 0%, #03ccd9 100%);
        background: linear-gradient(to right, #7c81f4 0%, #03ccd9 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7c81f4', endColorstr='#03ccd9',GradientType=1 );
        top:6px;

      }
    }
  }


  &.with-carmelo{
    min-height: 180px;
    padding-right:130px;



    h5{
      margin-bottom:10px;
    }

    .carmelo{
      bottom: 0;
      right: 0;
      position: absolute;

      img{
        max-width: 120px;
      }
    }


    &.carmelo-left{
      padding-right:30px;
      padding-left: 130px;

      .carmelo{
        right:auto;
        left: 0;

        img{
          -webkit-transform: rotateY(180deg);
          -moz-transform: rotateY(180deg);
          -ms-transform: rotateY(180deg);
          -o-transform: rotateY(180deg);
          transform: rotateY(180deg);
        }
      }
    }


  }



}


.requests-list{


  .header-item{
    font-weight: bold;
    padding-top: 0!important;
    padding-bottom: 0!important;
    border: 0!important;
    margin-top:10px;
    text-transform: uppercase;
    font-size: .8em;
    color: #666;
  }

  .request-item{

    border: 1px solid #ededed;
    padding: 15px;
    transition: all .4s ease;
    margin-bottom:3px;

    .request-title{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      a{
        text-decoration: none;
        font-weight: bold;
      }

    }

    .request-date{

    }



    &.item-row{

      &:hover{
        box-shadow: 0 0 10px 0 rgba(0,0,0,.05);
        background: #F4F9FC;
        border-color: #e1edf5;
      }

    }
  }

  @media (max-width: 767px) {

    margin-left:-25px;
    margin-right:-25px;
    margin-top:25px;

    .header-item{
      display: none;
    }

    .request-item{

      background: #FFF;
      border: 1px solid #ededed;
      margin-bottom:10px;
      padding: 15px 25px;

      .request-date{
        margin-top:5px;
      }
    }

  }


}



.status-label{

  color: #FFF;
  border-radius: 3px;
  font-size: 12px;
  padding: 2px 8px;
  display: inline-block;
  font-weight: bold;

  &.in-progress{
    background: orange;
  }

  &.completed{
    background: green;
  }

  &.waiting{
    background: gray;
  }

  &.for-approval{
    background: $primary;
  }

}

.single-request-wrap{


  h4.request-title{
    font-size: 1.1em;
    color: $primary;
    width:50%;
  }

  .request-status-wrap{
    float: right;
    position: relative;


    .change-status-wrap{

      position: absolute;
      padding: 15px;
      right: 0;
      background: #FFF;
      top:30px;
      border: 1px solid #ecefff;
      border-radius: 5px;
      min-width: 170px;
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
      transform: translateY(40px);
      transition: all .4s ease;
      font-size: 10px;

      &:after{
        top: -30px;
        height: 30px;
        left: 0;
        right: 0;
        position: absolute;
        content: '';
      }

      button{
        background: none;
        display: block;
        width:100%;
        margin-bottom:2px;
        border: 1px solid #FFF;
        outline: none;
        transition: all .4s ease;

        &.pending{
          background-color: #84cb88;
          color: #FFF;
          border-radius: 3px;

        }

        &.in-progress{
          background-color: #fac166;
          color: #FFF;
          border-radius: 3px;

        }

        &.completed{
          background-color: #7d94f4;
          color: #FFF;
          border-radius: 3px;

        }


        &:hover{

        }
      }
    }


    &:hover{
      .change-status-wrap{
        visibility: visible;
        opacity: 1;
        pointer-events: inherit;
        transform: translateY(0);
      }
    }

  }

  .request-status{

    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 10px;
    font-weight: 500;
    padding: 3px 5px 4px;
    border-radius: 3px;

    &.status-pending{
      color: #fff;
      background-color: #84cb88;
    }

    &.status-in-progress{
      color: #fff;
      background-color: #fac166;
    }

    &.status-completed{
      color: #fff;
      background-color: #7d94f4;
    }

  }

  .request-meta{
    color: #999;
    font-size: 12px;
    margin-bottom:10px;

    .sep{
      display: inline-block;
      margin: 0 10px;
      position: relative;
      overflow: hidden;
      text-indent: -999px;
      width: 2px;
      vertical-align: middle;


      &:after {
        height: 10px;
        position: absolute;
        top: 50%;
        width: 1px;
        margin-top: -5px;
        content: '';
        left: 0;
        background: #dedede;
      }
    }

  }


  @media (max-width: 767px) {


    h4.request-title{
      width:100%;
    }

    .request-status{
    }

  }

}




@mixin editor_styles{

  h1, h2, h3 {
    color: #37c3ff !important;
    font-weight: 300 !important;
    text-align: left;
  }

  h1 {
    font-size: 1.6em;
    margin: 0 0 1em;

  }

  h2 {
    font-size: 1.4em;
    margin: 0 0 .7em !important;
    padding: 0 !important;
    &:after {
      display: none;
    }
  }

  h3 {
    font-size: 1.2em;
    margin: 0 0 .7em !important;
    padding: 0 !important;
  }

  pre, ol, ul, p {
    margin-bottom: 20px;
  }

  ol,
  ul {
    margin-left: 20px;
    padding-left: 0;
  }

  ul {
    padding: 0;
    margin-left: 0;

    li {
      list-style: none;
      padding-left: 20px;
      position: relative;
      margin-bottom: 5px;

      &:before {
        width: 6px;
        height: 6px;
        left: 0;
        background: $primary;
        content: '';
        position: absolute;
        top: 10px;
        border-radius: 50%;
      }
    }
  }


  pre {
    display: block;
    font-size: .95em;
    color: #E91E63;
    overflow: visible;
    white-space: pre-wrap;
  }

  blockquote {
    padding: 20px 0 20px 50px;
    position: relative;
    border: 0!important;

    &:before {
      left: 0;
      top: 20px;
      font-size: 22px;
      color: #dedede;
      position: absolute;
      content: "\f10d";
      font-weight: bold;
      font-family: "Font Awesome 5 Free";
    }
  }

  strong {
    font-weight: 600;
  }

  div > ul{
    margin-top: 20px;
  }

  ul+br{
    display: none;
  }
}


.single-request-wrap-v2{

  //font-size: 0.9em;
  //font-family: acumin-pro,sans-serif;
  //line-height: 1.6;

  h3{
    font-weight: 300;
  }

  .DraftEditor-editorContainer{
    line-height: 1.6;
    font-size: 1em;
    //font-family: acumin-pro,sans-serif;


  }

  p+br{
    //remove br after p tag
    display: none;
  }

  .request-content-wrap {

    @include editor_styles;


    .request-email-subject{

      margin-bottom: 20px;
      color: $primary;
      font-size: 16px;
      font-weight: bold;

    }

    .gmail_signature,
    .gmail_quote {
      display: none;
    }

    .request-attachments strong {
      font-weight: bold;
      font-size: .9em;
      color: #666;
      text-transform: uppercase;
      display: block;
      padding-bottom: 5px;
    }

    .request-content {
      transition: all .4s ease-out;
      //padding: 40px;
      //border-radius: 5px;
      //border: 1px solid #ecefff;
      background: #FFF;

      .draftjs-editor-wrap{
        border-color: #ecefff;
      }

    }

    .work-request-comments{
      position: relative;


    }

    .comment-item{

    }


    //hide Signature
    #Signature{
      //display: none;
    }

  }
  @media (max-width: 767px) {
    .request-content-wrap {

      h1 {
        font-size: 1.4em;
        margin: 0 0 1em;

      }

      h2 {
        font-size: 1.3em;
        margin: 0 0 .7em !important;
        padding: 0 !important;
        &:after {
          display: none;
        }
      }

      h3 {
        font-size: 1.1em;
        margin: 0 0 .7em !important;
        padding: 0 !important;
      }


      .request-content {

        padding: 20px ;


      }

      .work-request-comments{
        margin-top: 10px;
      }

    }
  }
}

.request-attachments{
  padding-top: 20px;
  font-size: .9em;
  color: #666;

  .fa{
    font-size: .7rem;
    color: #666;
  }
}


.work-request-comments{


  .comment-item{
    position: relative;
    padding-left: 60px;
    margin-bottom: 10px;

    .comment-content{
      //border: 1px solid #ecefff;
      padding: 15px 0 0 10px;
      //border-radius: 5px;
      transition: all .4s ease-out;
      //background: #fff;
      //box-shadow: 0 20px 20px 0 rgba(0,0,0,.03);



      &:hover{
        //box-shadow: 0 0 30px 0 rgba(0,0,0,.1);
      }
    }

    .support-estimate{

      span.estimate-value{
        font-weight: bold;
        color: $primary;
      }
    }

    .date{
      font-size: .8em;
      color: #999;
      font-weight: 500;
      margin-bottom:10px;
    }

    .sep{
      display: inline-block;
      margin: 0 8px;
      position: relative;
      overflow: hidden;
      text-indent: -999px;
      width: 2px;
      vertical-align: middle;

      &:after {
        height: 10px;
        position: absolute;
        top: 50%;
        width: 1px;
        margin-top: -5px;
        content: '';
        left: 0;
        background: #dedede;
      }
    }

    button{
      margin-top:10px;
    }

    .photo{
      left: 0;
      top: 0;
      position: absolute;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid #ecefff;
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 44px;

      img{
        max-width: 25px;
      }
    }
  }

  .comment-item-form{

    .comment-content{
      box-shadow: none!important;
      border: 0;
      padding: 0;
      border-radius: 5px;
      background: none;

      .draftjs-editor-wrap{
        background: #FFF;
      }

      .request-form-wrap{
        width:100%;
        max-width: 100%;
      }

      button{
        margin-top:0;
        margin-bottom:10px;
      }
    }

    .attach-file-btn{
      position: absolute;
      right: 10px;
      bottom: 10px;
      outline: none;

      .attach-icon{
        display: block;
        background-image: url("../images/Attach.svg");
        background-repeat: no-repeat;
        background-size: 70%;
        background-position: center;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        transition: all .4s ease;


        &:hover{
          background-color: #FFF;
          box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
        }
      }
    }

  }


  @media (max-width: 767px) {

    margin-top:15px;

    .comment-item{
      padding-left:50px;

      .photo{
        width: 40px;
        height: 40px;
        line-height: 40px;

        img{
          max-width: 25px;
        }
      }

      .comment-content{
        padding:20px;
      }
    }

    .comment-item-form .comment-content{
      padding: 0;
    }

  }
}


.request-landing-content{
  max-width: 700px;
  margin:0 auto;
  text-align: center;
  margin-bottom: 50px;

  ul{

    margin-top:40px;

    li{
      display: inline-block;
      margin:0 30px;

      a{
        color: #333;
        text-decoration:none!important;
        transition: all .4s ease;

        &:hover,
        &.active{
          color: $primary;
        }
      }

    }

  }

  @media (max-width: 767px) {

    ul{

      li{

        margin: 0 10px;
      }
    }
  }
}


.add-work-request-action{
  text-align: left;


  a{
    color: orangered;
  }

  @media (max-width: 767px) {
    text-align: left;
  }
}





.DraftEditor-editorContainer{
  border: 0!important;
}

.DraftEditor-editorContainer,
.hs-editor-content{
  padding: 30px;
  border: 1px solid #ededed;
  border-radius: 5px;



  &.single-page,
  &.hs-editor-comment-content{
    border: 0;
    padding: 0;
  }

  blockquote{
    padding-left: 20px;
    border-left: 4px solid #ededed;
    margin-bottom: 15px;
  }


  h1,h2,h3{
    text-transform: none;
    color: #333;
  }

  h1{
    font-size: 1.6em!important;
  }

  h2{
    font-size: 1.4em!important;
  }

  h3{
    font-size: 1.2em!important;
  }

  pre{
    white-space: normal;
    display: block;
    font-size: 87.5%;
    color: #212529;
  }

  code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word;
  }

  ul{
    margin-left:0!important;
    margin-bottom: 20px;

    li{
      list-style: none;
      position: relative;
      padding-left: 20px;
      margin-bottom:10px;

      &:before{
        background: $primary;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top:8px;
        content: '';
      }
    }
  }

  ol{
    margin: 0 0 0 17px;
    padding: 0;
    margin-bottom: 20px;

    li{
      margin-bottom:10px;
    }

  }



  @media (max-width: 767px) {
    padding: 20px;
    border: 1px solid #ededed;
    border-radius: 5px;

    blockquote {
      padding-left: 20px;
      border-left: 4px solid #ededed;
      margin-bottom: 15px;
    }


    h1 {
      font-size: 1.4em !important;
    }

    h2 {
      font-size: 1.3em !important;
    }

    h3 {
      font-size: 1.2em !important;
    }
  }


}


body.make-request-page{


  main{

  }

  .wrap{
    padding-bottom: 50px;
  }

  .page-content{
    background: #FFF;
    max-width: 1000px;
    margin:0 auto;


    &:before,
    &:after{
      display: none;
    }


    h2{
      font-weight: 300;
      color: $primary;
      text-transform: none;
      position: relative;
      padding-bottom: 20px;
      margin-bottom: 30px;

      &:after{
        width: 250px;
        left:50%;
        margin-left:-125px;
        bottom: 0;
        height: 1px;
        background: #efefef;
        position: absolute;
        content: '';
      }
    }
  }

  .make-request-form-wrap{

    max-width: 800px;
    margin:0 auto;

    h2,p{
      text-align: center;
    }

    p{
      max-width: 350px;
      margin:0 auto;
      font-size: 14px;
    }

    .request-form{
      margin-top: 50px;
    }

    .request-form-type{
      text-align: center;
      margin-top:50px;


      a{
        text-decoration: none;
        background: #FFFFFF;
        box-shadow: 1px 5px 15px 0 rgba(67,86,100,0.06), 0 8px 14px 0 rgba(67,86,100,0.08);
        border-radius: 2px;
        display: inline-block;
        padding: 15px 10px;
        margin: 0;
        font-weight: 600;
        font-size: .9em;
        width:100%;
        position: relative;
        background: $primary;
        color: #FFF;
        overflow: hidden;
        transition: all .4s ease;

        &:after{
          height: 5px;
          background: $primary;
          content: '';
          bottom:0;
          left: 0;
          right: 0;
          position: absolute;
          display: none;
        }

        &:hover{
          transform: translateY(-5px);
        }

        &.active{
          background: #FFF;
          color: $primary;

          &:after{
            display: block;
          }
        }
      }
    }


    .editor-placeholder{

      background: #f9faff;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      border: 10px solid #FFFF;

      .editor-placeholder-inner{
        position: relative;
        margin: 40px 30px 40px 30px;
        padding-left: 50px;
        max-width: 500px;
        color: #5e6063;

        a{
          color: $primary;
        }

        .user-photo{
          position: absolute;
          width:30px;
          height: 30px;
          overflow: hidden;
          border-radius: 50%;
          left: 0;
          top: 0;
          padding: 5px;
          background: #FFF;
          line-height: 10px;
          border: 1px solid #ededed;
          img{
            width:100%;
            height: auto;

          }
        }
      }
    }

    .draftjs-editor-wrap{
      border: 1px solid #eaeaea;
      border-radius: 3px;
      padding: 0 0 40px;
      position: relative;
      background: #f9faff;
      border-color: #e9eeff;
      position: relative;

      .inline-toolbar{
        display: none;
      }

      .DraftEditor-editorContainer{

        ol,
        ul{
          margin-left: 20px!important;
          padding-left: 17px!important;

          li{
            padding-left: 0;
            margin-bottom: 0;
          }

        }

        ul{

          li{
            list-style: disc;
            &:before{
              display: none;
            }
          }
        }
      }

      .DraftEditor-editorContainer{
        min-height: 200px;
      }

      .draftJsToolbar__button__qi1gf{
        color: #8c929f;
      }
      .draftJsToolbar__button__qi1gf svg{
        fill: #646b7b;
      }
      .editor-static-toolbar{
        border-color: #e9eeff;
      }
    }

    .form-control{
      background: #f9faff;
      border-color: #e9eeff;
    }
    .form-label{
      text-transform: uppercase;
      //color: #B0BAC9;
      font-weight: 600;
      font-size: .8em;
    }

    .attachments{

      padding: 10px 0;
      font-size: .8em;

      .attachment-item{

        margin-bottom: 5px;
        font-size: 14px;

        span{

          display: inline-block;
          color: #dedede;
          margin-right: 10px;
          font-weight: bold;
          background: #fafafa;
          text-align: center;
          width: 20px;
          height: 20px;
          line-height: 17px;
          font-size: 12px;
          border: 1px solid #ededed;
          border-radius: 50%;
          cursor: pointer;
          -webkit-transition: all .4s ease;
          transition: all .4s ease;

          &:hover{
            border: 1px solid #da493f;
            background: #da493f;
            color: #FFF;
          }

        }
      }

    }

    .attach-file-btn{
      position: absolute;
      right: 10px;
      bottom: 10px;
      outline: none;

      .attach-icon{
        display: block;
        background-image: url("../images/Attach.svg");
        background-repeat: no-repeat;
        background-size: 70%;
        background-position: center;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        transition: all .4s ease;


        &:hover{
          background-color: #FFF;
          box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
        }
      }
    }
  }

}


.admin-dashboard-boxes{

  h3{
    font-size: 16px;
  }
}

.admin-list{

  padding: 30px;
  margin-bottom: 30px;
  border-radius: 5px;

  &:after{
    clear: both;
    content: '';
    display: block;
    position: relative;
  }

  .link-item{

    vertical-align: top;
    float: left;
    width:50%;
    position: relative;

    span.logo{
      left: 0;
      top: 5px;
      position: absolute;
      display: inline-block;
      overflow: hidden;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      line-height: 10px;
      padding: 5px;
      margin-right: 10px;
    }

    span.title{
      overflow: hidden;
      display: block;
      line-height: 1.4;
      //padding: 5px 0;
    }

    span.date{
      display: block;
      font-size: 12px;
      color: #666;
    }

    a{
      color: #333;
      margin-bottom: 3px;
      display: block;
      padding: 10px 0 10px;
      text-decoration: none;
      transition: all .4s ease;
      vertical-align: top;
      margin-left:5px;
      margin-right:5px;
      position: relative;
      padding-left:40px;

      &:hover{
        color: $primary;
      }

    }
  }

  @media (max-width: 600px) {

    .link-item{
      width:100%;
    }
  }
}


.request-form-interactive{


  h3.h3-heading{
    font-size: 1rem;
    font-weight: 300;
    text-transform: uppercase;
    color: #B0BAC9;
  }

  h4.h4-heading{
    font-size: 1rem;
    font-weight: 300;
    color: #333;
  }

  ul.feature-list{
    position: relative;
    display: block;
    margin-left:-10px;
    margin-right:-10px;
    margin-top:40px;

    &:after{
      display: block;
      content: '';
      clear: both;
    }

    li.feature-item{

      display: block;
      float: left;
      text-align: center;
      width: 33.333%;
      margin-bottom:10px;
      padding: 0 10px;
      position: relative;
      cursor: pointer;



      span{
        display: block;
        padding: 30px 20px 30px 90px;
        border-radius: 4px;
        border: 1px solid #e6ecff;
        position: relative;
        text-align: left;

        font-size: .9em;
        transition: all .4s ease;

        i{
          position: absolute;
          left: 10px;
          top:50%;
          transform:translateY(-50%);
          background: #f2f4f7;
          text-align: center;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          line-height: 60px;
          transition: all .4s ease;
          color: #333;
        }

        &:after{
          position: absolute;
          width:20px;
          height: 20px;
          line-height: 20px;
          font-size: 11px;
          background: $primary;
          border-radius: 50%;
          top:-10px;
          right:-10px;
          opacity: 0;
          visibility: hidden;
          transition: all .4s ease;
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f00c";
          color: #FFF;
          text-align: center;
        }
      }

      &:hover{

        span{
          box-shadow: 0 0 2px 0 rgba(0,0,0,.2);
          border-color: #FFF;

          i{
            -webkit-transform: translateY(-50%) translateX(5px);
            -moz-transform: translateY(-50%) translateX(5px);
            -ms-transform: translateY(-50%) translateX(5px);
            -o-transform: translateY(-50%) translateX(5px);
            transform: translateY(-50%) translateX(5px);

          }
        }
      }

      &.checked{

        span{
          border-color: $primary;
          box-shadow: 0 0 2px 0 rgba(0,0,0,.2);

          &:after{
            visibility: visible;
            opacity: 1;
          }
        }

      }
    }
  }

  .feature-sub-list-wrap{
    margin-top: 40px;

    h4{
      margin:0 0 10px;
      font-size: 1.2rem;
    }

    ul.feature-sub-list{
      margin-top:0;

      li{
        width:25%;

        span{
          padding: 10px 20px;
          font-size: .8rem;
        }
      }
    }

    .other-item{

      .form-control{
        background: #FFF;
        font-size: .8rem;
        color: #333;
        padding: 10px 20px;
      }
    }
  }

  .form-editor{
    margin-top: 50px;
  }

  @media (max-width: 991px) {

    .feature-sub-list-wrap{
      ul.feature-sub-list{

        li{
          width:33.3%;

          span{
            padding: 10px 15px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {

    ul.feature-list{
      margin-top:40px;

      li.feature-item {

        width: 50%;
        margin-bottom: 10px;
        padding: 0 10px;

        span{
          display: block;
          padding: 20px 20px 20px 60px;
          border-radius: 4px;
          border: 1px solid #e6ecff;
          position: relative;
          text-align: left;

          font-size: .9em;
          transition: all .4s ease;

          i{
            left: 10px;
            width: 40px;
            height: 40px;
            line-height: 40px;
          }

          &:after{
            position: absolute;
            width:20px;
            height: 20px;
            line-height: 20px;
            font-size: 11px;
            background: $primary;
            border-radius: 50%;
            top:-10px;
            right:-10px;
            opacity: 0;
            visibility: hidden;
            transition: all .4s ease;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f00c";
            color: #FFF;
            text-align: center;
          }
        }


      }
    }

    .feature-sub-list-wrap{
      ul.feature-sub-list{

        li{
          width:100%;
          margin-bottom:5px;

          span{
            padding: 10px 15px;
          }
        }
      }
    }

  }
}


body.single-request-page{

  @media (max-width: 767px) {

    .page-content{
      padding: 0;
      background: none;
      border: 0;
    }
  }
}


.work-request-comments .comment-item-form .comment-content .draftjs-editor-wrap input {
  color: #FFF!important;
}


.page-wrap .draftjs-editor-wrap .DraftEditor-root .DraftEditor-editorContainer{

  @include editor_styles;
}



.support-requests-wrap{

  margin: 0 auto;
  margin-top: 15px;

  h3{
    font-weight: normal;
    margin-bottom: 40px;
    color: #333;
    font-size: 1.3em;
  }

  .request-item{

    .row{
      div{
        padding-top:5px;
        padding-bottom:5px;
      }
    }

    a{

    }

    .request-status{

      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      font-size: 10px;
      font-weight: 500;
      padding: 3px 5px 4px;
      border-radius: 3px;

      &.status-pending{
        color: #fff;
        background-color: #84cb88;
      }

      &.status-in-progress{
        color: #fff;
        background-color: #fac166;
      }

      &.status-completed{
        color: #fff;
        background-color: #7d94f4;
      }

    }

  }

  .project-scope-no-results{
    padding: 0;
  }
}