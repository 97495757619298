.sitemap-control{
  position: absolute;
  right:20px;
  top: 30px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.05);
  z-index: 22;

  span{
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #FFF!important;
    background: $primary;
    transition: all .4s ease;
    text-decoration: none!important;

    &:hover{
      background: #0976B4;
    }
  }

  span.up{
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  span.down{
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top: 1px solid #0976B4;
  }

}

.sitemap-viewer{
  position: relative;
  height: calc(100vh - 60px);
  margin-top: -40px;
  margin-left: -40px;
  margin-right: -40px;
  margin-bottom:-40px;

  @media (max-width: 991px) {
    margin: -20px;
    padding: 20px;


  }
}

.sitemap-builder-container-wrap{

  backface-visibility: hidden;
  perspective: 1000;
  transform: scale(1);
  position: absolute;
  overflow: scroll;
  font-size: 14px;
  padding: 0 15px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .sitemap-section{
    margin-bottom: 3em;
  }
}

@mixin itemWrap(){

  .item-wrap{


    &:after{
      position: absolute;
      content: '';
      height: 1px;
      background: $primary;
      top: -1.9375em;
      right: -0.5em;
      left: -0.5em;
    }

    .item{
      display: inline-block;
      text-align: center;
      padding: 0 .031em;
      border-radius: 3px;
      position: relative;

      width:12.375em; //width: 9.375em;
      min-height: 60px;

      -webkit-transition: all .1s ease;
      -moz-transition: all .1s ease;
      -ms-transition: all .1s ease;
      -o-transition: all .1s ease;
      transition: all .1s ease;
      background: #FFF;
      color: $primary;
      font-weight: 500;
      font-size: 15px;

      border: 1px solid $primary;




      .handle{
        cursor: move;
        background: repeat;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 10px;
        z-index: 3;

        &:before{
          content: '...';
          position: absolute;
          top: 1px;
          left: 5px;
          line-height: 0;
          color: $primary;
        }

        &:after{
          content: '';
          position: absolute;
          top: 10px;
          left: 0;
          right: 0;
          border-bottom: 1px solid $primary;
        }

      }

      .txt{
        display: block;
        padding: 0 .3125em;



      }

      .add-sub-item{
        font-weight: bold;
        background: #f5f6fa;
        margin-bottom:3px;
        border-radius: 3px;
        cursor: pointer;
        opacity: 0;
        transition: all .4s ease;
      }

      .sub-item{
        margin-bottom:3px;
        border-radius: 3px;
        text-align: left;
        font-size: 11px;
        outline: none!important;
        font-weight: normal;
        color: #FFF;

        min-height: 21px;
        padding-right: 20px;
        word-break: break-word;
        position: relative;

        span.sub-item-text{
          display: block;
          outline: none!important;
          padding: 2px 5px;
        }


        span.sub-item-actions{
          cursor: pointer;
          position: absolute;
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
          right: 0;
          width: 18px;
          text-align: center;
          font-size: 20px;
          line-height: 14px;
          color: #FFF;
          top: 0;
          bottom: 0;


          &:after{
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;
            text-align: center;
            content: '...';
            white-space: nowrap;
            color: #FFF;
            font-size: 14px;
            line-height: 15px;
          }
        }

        &:hover{
          span.sub-item-actions{
            opacity: 1;
            visibility: visible;
            pointer-events: inherit;
          }
        }
      }

      .textarea{

        display: inline-block;
        margin: 1em 0 0;
        padding: 4px 0 5px;
        text-align: left;
        border: 0;
        -webkit-appearance: none;
        width:100%;
        outline: none!important;
        //border: 0;
        font-weight: 500;
        word-break: break-word;
        white-space: normal;
        //overflow: hidden;
        //margin-bottom: 1.8em;

        font-size: .9em;
        color: $primary;
      }




      .act{
        position: absolute;
        bottom: -1.7em;
        left: 0;
        right: 0;
        opacity: 0;
        z-index: 22;
        text-align: center;
        -webkit-transition: all .4s ease;
        -moz-transition: all .4s ease;
        -ms-transition: all .4s ease;
        -o-transition: all .4s ease;
        transition: all .4s ease;
      }

      span.remove,
      span.add{
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: inline-block;
        line-height: 20px;
        font-size: 12px;
        font-weight: bold;
        background: #0988cd;
        color: #FFF;
        text-align: center;
        margin: 0;
        cursor: pointer;
        -webkit-transition: all .4s ease;
        -moz-transition: all .4s ease;
        -ms-transition: all .4s ease;
        -o-transition: all .4s ease;
        transition: all .4s ease;
      }



      span.remove{
        background: red;
        color: #FFF;
      }


      span.add:hover{
        background: #0988cd;
        color: #FFF;
      }

      span.remove:hover{
        background: red;
        color: #FFF;
      }

      &:hover{

        .act{
          opacity: 1;
        }

        .add-sub-item{
          opacity: 1;
        }
      }
    }


  }

}


.sitemap-builder-container{

  margin: 50px 0;
  transform-origin: 50% 0 ;

  .sitemap-home{
    text-align: center;

    @include itemWrap;


    .item-wrap:after{
      display: none;
    }
    .item{

    }

    .add{
      margin-left:-0.625!important;
    }
  }

  ul.sitemap-lvl{
    text-align: center;
    margin-top: 50px;
    white-space:nowrap;
    min-height: 205px;
    position: relative;



    &:not(:empty){

      &:before{
        width: 1px;
        height: 23px;
        background: $primary;
        position: absolute;
        top: -50px;
        left:50%;
        content: '';
      }


    }



    li{

      position: relative;
      display: inline-block;
      margin: 0 0.3125em;
      vertical-align: top;
      white-space: nowrap;

      &.ui-sortable-helper{

        .handle{
          background: $primary!important;

          &:before{
            color: #FFF!important;
          }
        }

        &:before{
          display: none!important;
        }

        > .item-wrap:after{
          display: none!important;
        }

      }


      &.ui-sortable-placeholder{
        background: #FFF!important;
        border: 1px dashed $primary!important;
        border-radius: 3px;
        visibility: visible!important;
        min-height: 60px;




        &:before{
          display: none!important;
        }

      }

      @include itemWrap;


      &:first-child{

        > .item-wrap{

          &:after{
            left:50%;
          }
        }
      }

      &:last-child{

        > .item-wrap{

          &:after{
            right:50%;
          }
        }
      }

      &:before{
        width:1px;
        height: 1.875em;
        background: $primary;
        left:50%;
        top:-1.875em;
        position: absolute;
        content: '';
      }

    }
  }
}



#sub-item-actions-nav{
  display: none;
  position: absolute;
  background: #FFF;
  box-shadow: 0 20px 20px 0 rgba(0,0,0,.1);
  padding: 20px;
  font-size: 12px;
  border-radius: 2px;
  max-width: 160px;
  text-align: center;
  z-index: 222;
  margin-left: -80px;
  margin-top: 11px;

  &.pakita{
    display: block;
  }

  &:after{
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #FFF;
    border-width: 6px;
    margin-left: -6px;
  }

  .spacer{
    margin-top: 10px;
    border-bottom: 1px solid #ededed;
    margin-bottom: 10px;
  }

  .sub-item-colors{

    span{
      margin: 0 2px;
      width: 16px;
      height:  16px;
      border-radius: 50%;
      display: inline-block;
      cursor: pointer;
      transition: all .4s ease;

      &:hover{
        transform: scale(1.1)
      }
    }
  }

  .remove-sub-item{
    color: #8392a5;
    cursor: pointer;
  }

  .fa{
    font-size: 11px;
  }
}