

.d6-data-tables-wrap {
  position: relative;//do no change
  visibility: hidden;


  th.sorting{
    &:before,
    &:after{
      visibility: hidden;

    }
  }

  th.sorting_desc,
  th.sorting_asc{
    &:before,
    &:after{
      right: 0.5em!important;;
      bottom: 0.9em!important;
      font-size: 11px;
      opacity: 0.3;
    }
  }

  th.sorting_asc{

    &:after{
      visibility: hidden;
    }

  }

  th.sorting_desc{

    &:before{
      visibility: hidden;
    }
  }

  table.dataTable{
    border-collapse: collapse!important;
    width:100%!important;
    border-left: 0!important;
    border-right: 0!important;
    border-top: 0!important;
    td,th{
      border-left: 0!important;
      border-right: 0!important;
    }
    th{
      border-bottom-width: 2px!important;
      border-top: 0!important;
    }
  }


  table.dataTable thead .sorting_asc,
  table.dataTable thead .sorting_desc,
  table.dataTable tbody td.sorting_1{
    background-image: none;
    background-color: #f7fafd;
    position: relative;
  }


  span.up-arrow{
    color: green!important;
  }

  span.down-arrow{
    color: red!important;;
  }



  &.pakita{
    visibility: visible;
  }


  .dataTables_wrapper {

    .dataTables_paginate .paginate_button{
      padding:0!important;
      margin: 0!important;
      font-size: 11px!important;
      outline: none!important;
      box-shadow: none!important;

      a{
        padding: 7px 7px;
        outline: none!important;
        box-shadow: none!important;
      }
    }


    .row:nth-child(2){

      padding-left: 15px;
      padding-right: 15px;

      .col-sm-12{
        overflow: hidden;
        padding: 0;
      }

    }
  }

  .form-control{
    padding: 5px 10px!important;
  }

  select.form-control{
    padding-right: 20px!important;
  }

  div.dataTables_wrapper div.dataTables_info{
    font-size: 11px;
    padding-top:0;
    margin-top: 10px;
  }

  .dtr-details{
    width:100%;
    font-size: 12px;

    li{
      white-space: normal!important;

      .dtr-title,
      .dtr-data{
        display: block!important;
      }
    }
  }

  table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td.dtr-control:before,
  table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th.dtr-control:before,
  table.dataTable.dtr-inline.collapsed>tbody>tr.parent>td.dtr-control:before,
  table.dataTable.dtr-inline.collapsed>tbody>tr.parent>th.dtr-control:before{
    font-family: Font Awesome\ 5 Free;
    border: 0!important;
    background-color: transparent;
    box-shadow: none;
    color: #98a1ae;
    font-size: 10px!important;
    margin-top:-7px;
  }
  table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td.dtr-control:before,
  table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th.dtr-control:before{
    content: "\f054"!important;

  }

  table.dataTable.dtr-inline.collapsed>tbody>tr.parent>td.dtr-control:before,
  table.dataTable.dtr-inline.collapsed>tbody>tr.parent>th.dtr-control:before{

    content: "\f078"!important;
  }
}


.data-table-content{




  .datatable-sheets-select-wrap{
    position: relative;

    &:before{
      font-family: Font Awesome\ 5 Free;
      border: 0!important;
      background-color: transparent;
      box-shadow: none;
      color: #98a1ae;
      font-size: 10px!important;
      margin-top:-7px;
      content: "\f078"!important;
      position: absolute;
      right: 10px;
      top:50%;
      z-index: 22;
      font-weight: bold;
    }
  }

  .datatable-sheets-select{
    padding-right: 30px!important;
    appearance: none;
  }


  .data-table-cards {
    position: relative;//do not remove

  }

  .datatable-sheet{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    visibility: hidden!important;
    pointer-events: none;
    z-index: -1;
    opacity: 0;
    transition: all .7s ease;
    transform: translateY(150px);

    &.show{
      transform: translateY(0);
      opacity: 1;
      left: auto;
      right: auto;
      top: auto;
      position: relative;
      visibility: visible!important;
      pointer-events: inherit;
      z-index: 1;
    }
  }

  .dataTables_wrapper .row:first-child{
    padding-left: 5px;
    padding-right: 5px;
    background: #f5f6fa;
    margin-right: -20px!important;
    margin-left: -20px!important;
    padding-top: 20px;
    margin-top: -20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e1e5ee;

    @media (max-width: 575px) {
      margin-right: -15px!important;
      margin-left: -15px!important;
    }
  }
}




.data-table-content.loading{
  opacity: .4;
}


.data-table-content.compact{

  .card{
    box-shadow: none;
    border: 0;
    background: none!important;
  }

  .card-header{
    border-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .row:first-child {

    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
    border-top: 1px solid rgba(72, 94, 144, 0.16);
    background: #FFF;


  }


  .d6-data-tables-wrap .dataTables_wrapper .row:nth-child(2) {
    padding-left: 0;
    padding-right: 0;
    background: none;
  }

  table.dataTable {
    border: 0;
    th, td{
      border: 0;
      border-bottom: #f5f6fa 4px solid!important;
      font-size: 13px;
    }

    td{
      background: #fff;
    }
  }

  .d6-data-tables-wrap table.dataTable thead .sorting_asc,
  .d6-data-tables-wrap table.dataTable thead .sorting_desc{
    background: none;
  }
  .d6-data-tables-wrap table.dataTable tbody td.sorting_1{
    background: #FFF;
  }
}




.table-template{

  iframe{
    width: 100%;
    background: none;
    min-height: 300px;
    background-color: #f5f6fa;
  }
}