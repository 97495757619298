.onboard-page{
  background: #FFF;
  position: relative;
  height: 100vh;

  .onboard-progress-bar-wrap{
    padding:100px 0 0;
    max-width: 1000px;
    margin: 0 auto;
    transition: all 0.7s cubic-bezier(0.5, 0.005, 0.075, 0.985);
    opacity: 0;
    visibility: hidden;
    transform: scale(.5);

    &.show{
      transform: scale(1);
      visibility: visible;
      opacity: 1;
    }

    .step{

      color: #8B939C;
      font-size: 12px;
      font-weight: bold;


      span{
        display: inline-block;
        margin-left: 10px;
        font-weight: bold;
        color: #35383B;
        font-size: 16px;
      }
    }

    .onboard-progress-bar{
      margin-top: 5px;
      height: 5px;
      border-radius: 5px;
      background: #F2F2F2;
      overflow: hidden;
      position: relative;

      .onboard-bar{
        background: #0091FF;
        top: 0;
        left: 0;
        bottom: 0;
        width:0;
        position: absolute;
        transition: all 0.7s cubic-bezier(0.5, 0.005, 0.075, 0.985);
      }


    }
  }



  .onboard-page-section{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    visibility: hidden;
    transition: all 1s cubic-bezier(0.5, 0.005, 0.075, 0.985) ;
    opacity: 0;
    transform: scale(.5);

    &.fade-up{
      transform: translateY(100vh);
    }

    .onboard-page-section-content{
      height: 100vh;
    }

    .step-container{
      max-width: 500px;

      .form-wrap{
        max-width: 400px;
        margin:0 auto;
      }

      h2{
        font-size: 28px;
      }

      p{
        color: #7F8792;
      }
    }
  }

  .onboard-page-section{

    &.active{
      visibility: visible;
      transform: scale(1);
      opacity: 1;
    }

    &.fade-up.active{
      transform: translateY(0);
    }
  }

  .onboard-page-section{

    &.done{
      visibility: hidden;
      transform: scale(1.5);
      opacity: 0;
    }

    &.fade-up.done{
      transform: translateY(-100vh);
    }
  }

  .signup-logo{
    position: absolute;
    top:20px;
    left:20px;
    margin-left:0;
    max-width:130px;

    @media (min-width: 992px) {
      left:30px;
      margin-left:0;
    }
  }

  .signup-panel-wrap{

    position: relative;

    &.show{
      .signup-panel .signup-panel-content{
        transform: scale(1);
        opacity: 1;
      }
    }

    @media (min-width: 992px) {
      height: 100vh;
    }

  }

  .signup-panel{
    //width:50%;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;

    .signup-panel-content{
      margin:0 auto;
      width:100%;
      transition: all 0.7s cubic-bezier(0.5, 0.005, 0.075, 0.985) ;
      opacity: 1;
      transform: scale(.90);
    }

    &.signup-left-panel{

      color: #8A929B;

      h1{
        font-size: 20px;
        margin: 0;
        color: #000;
      }

      p{
        margin: 0;
        margin-top: 20px;

      }

      .form-label{
        font-weight: 500;
        color: #000;
      }

      .form-group{
        margin-top: 10px;
        margin-bottom: 0;
      }

      .form-wrap{
        margin-top: 20px;
      }

      .signup-panel-content{
        max-width: 400px;
      }

      @media (min-width: 992px) {

        h1{
          font-size: 26px;
        }
      }

    }

    &.signup-right-panel{

      background: #0091FF;
      order: 1;
      color: #FFF;


      h1,h2, h3{
        color: #FFF;
      }

      h1{
        font-size: 26px;
        text-align: center;
      }

      h3{
        font-size: 19px;
      }

      .signup-panel-content{
        max-width: 500px;
      }

      @media (min-width: 768px) {

        h1{
          font-size: 35px;
        }

        h3{
          font-size: 24px;
        }
      }

      @media (min-width: 992px) {

        h1{
          font-size: 48px;
        }
      }

    }


    .cards{

      margin-top: 20px;

      .card{
        background: #FFF;
        box-shadow: 2px 2px 24px 0 rgba(0,0,0,0.07);
        border-radius: 4px;
        font-size: 13px;
        letter-spacing: 0;
        border: 1px solid #0091FF;

        padding: 30px 60px 30px 30px!important;
        transition: all .5s ease;
        position: relative;


        + .card{
          margin-top: 10px;
        }

        .icon{
          padding-right: 40px;
          padding-left: 10px;
          font-size: 20px;
          color: #000;
        }

        input{
          visibility: hidden;
          position: absolute;
        }

        h4{
          font-size: 14px;
          margin: 0;
        }

        p{
          font-size: 13px;
          margin: 0;
          color: #3E454A;
          margin-top:5px;

        }


        &.active{
          border: 1px solid #0091FF;
          background: rgba(0,145,255,0.08);

          .icon{
            color: #0091FF;
          }
        }


        &:hover{
          box-shadow: 2px 2px 24px 0 rgba(0, 0, 0, 0.2);
        }
      }
    }

    @media (min-width: 992px) {

      height: 100vh;
    }
  }



  .text-slider-wrap{
    margin-top: 50px;
    text-align: center;

    .slide-item{

      p{
        opacity: .67;
      }
    }
  }
  
  @media (min-width: 992px) {

    .signup-logo{

    }

    .text-slider-wrap{
      margin-top: 20vh;
    }
  }
}